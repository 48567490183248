
// header style hear

header.header-one{
    position: relative;
    .header--one-main{
        position: relative;
        .logo-area{
            padding: 40px 10px 40px 0;
            display: block;
        }
        .header-right-area{
            display: flex;
            justify-content: flex-end;
            .single-info-contact{
                display: flex;
                align-items: center;
                i{
                    width: 57px;
                    height: 57px;
                    border-radius: 50%;
                    background: #fff;
                    box-shadow: 0px 11px 22px rgba(0, 0, 0, 0.03);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 24px;
                    color: var(--color-primary);
                }
                .inner-content{
                    margin-left: 20px;
                    span{
                        display: block;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #74787C;
                    }
                    .title{
                        margin-bottom: 0;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        margin-top: 6px;
                        transition: .3s;
                        min-width: max-content;
                        @media #{$smlg-device} {
                            font-size: 15px;
                        }
                        &:hover{
                            color: var(--color-primary);
                        }
                    }
                }
                &.map{
                    margin-left: 40px;
                    padding-left: 40px;
                    border-left: 1px solid #D9D9D9;
                    @media #{$smlg-device} {
                        margin-left: 20px;
                        padding-left: 20px;
                        border-left: 1px solid #D9D9D9;
                    }
                }
            }
            
            .btn-area-header{
                display: flex;
                align-items: center;
                margin-left: 60px;
                @media #{$smlg-device} {
                    margin-left: 20px;
                }
            }
        }
    }
}

.header-top-one{
    @media #{$md-layout} {
        display: none;
    }
    @media #{$sm-layout} {
        display: none;
    }
}

.logo-md-sm-device{
    display: none;
    @media #{$sm-layout} {
        display: block;
    }
    .logo{
        img{
            max-width: 120px;
        }
    }
}


.header-nav{
    .sub-dropdown{
        position: relative;
        &::after{
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            content: '\f054';
            font-family:"Font Awesome 6 Pro" ;
            color: #000;
        }
        .third-lvl{
            border-radius: 0 0 10px 10px!important;
            li{
                min-width: max-content;
            }
        }
        &:hover{
            &::after{
                color: var(--color-primary);
            }
        }
    }
}

.sub-dropdown .submenu.third-lvl.base {
    display: block !important;
    border-radius: 0 0 10px 10px !important;
}

header.header-plumber{
    .header-top{
        height: 50px;
        background: var(--color-primary);
        clip-path: polygon(0 0, 100% 0, 94% 101%, 6% 100%);
        width: 1584px;
        margin: auto;
        display: flex;
        align-items: center;
        @media #{$laptop-device} {
            width: 100%;
            clip-path: none;
        }
        @media #{$smlg-device} {
            display: none;
        }
        .left-area{
            display: flex;
            align-items: center;
            margin-left: 125px;
            svg{
                margin-right: 15px;
            }
            p{
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #fff;
            }
        }
        .right-area{
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: 125px;
            .mail-area{
                margin-left: 50px;
            }
            .location-area,
            .mail-area{
                display: flex;
                align-items: center;
                .icon{
                    color: #fff;
                    margin-right: 10px;
                }
                p{
                    color: #fff;
                }
            }
        }
    }
}



// header two

.rts-header-area{
    &.header-two{
        position: relative;
        .header-main-area.header--sticky.sticky{
            top: 0;
            border-radius: 0 0 0;
            z-index: 100;
            width: 100%;
        }
        .header-top-two{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0 80px 0;
            .left{
                p{
                    margin: 0;
                    display: flex;
                    align-items: center;
                    color: #1F1F25;
                    a{
                        margin-left: 5px;
                        font-weight: 500;
                        font-size: 18px;
                        color: #1f1f25;
                    }
                }
            }
            .right{
                display: flex;
                align-items: center;
                .time-area{
                    display: flex;
                    align-items: center;
                    margin-right: 30px;
                    i{
                        color: #1C2539;
                    }
                    p{
                        margin: 0;
                        color: #1C2539;
                        margin-left: 15px;
                    }
                }
                .social-header{
                    display: flex;
                    align-items: center;
                    span{
                        color: #1C2539;
                        margin-right: 17px;
                    }
                    ul{
                        padding: 0;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        list-style: none;
                        li{
                            margin: 0 10px;
                            a{
                                i{
                                    color: #1C2539;
                                }
                            }
                        }
                    }
                }
            }
        }
        .header-main-area{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 70px;
            border-radius: 15px;
            width: 100%;
            z-index: 5;
            width: 77%;
            top: 0;
            margin: auto;
            @media #{$extra-device} {
                width: 86%;
            }
            @media #{$laptop-device} {
                width: 97%;
            }
            @media #{$smlg-device} {
                width: 100%;
                border-radius: 0;
            }
            @media #{$md-layout} {
                top: 0;
            }
            @media #{$sm-layout} {
                top: 0;
                padding: 15px 10px;
            }
            .button-action-area{
                display: flex;
                align-items: center;
                a.rts-btn.btn-primary{
                    // display: none;
                    @media #{$smlg-device} {
                        display: none;
                    }
                }
                .actions-area{
                    display: flex;
                    align-items: center;
                    gap: 25px;
                    margin-right: 30px;
                    .search-btn{
                        cursor: pointer;
                        i{
                            color: #1F1F25;
                        }
                    }
                    .menu-btn{
                        cursor: pointer;
                        i{
                            color: #1F1F25;
                        }
                    }
                }
            }
        }
    }
}
